
export const SIM_MANAGEMENT_LINKS = [
  {
    text: "SIM Server",
    routeName: "sim-server",
  },
  {
    text: "SIM Slots",
    routeName: "sim-slots",
  },
  {
    text: "SIM Card Infos",
    routeName: "sim-card-infos",
  },
  {
    text: "SIM to Project Assignment",
    routeName: "sim-to-project-assignment",
  },
];

export const CLIENT_MANAGEMENT_LINKS = [
  {
    text: "Client Locations",
    routeName: "client-locations",
  },
  {
    text: "Client Connection History",
    routeName: "client-connection-history",
  },
  {
    text: "Clients (Performance)",
    routeName: "clients-performance",
  },
  {
    text: "Modems Usage",
    routeName: "modems-usage",
  },
  {
    text: "Network Quality Measures",
    routeName: "network-quality-measures",
  },
];

export const SUPER_ADMIN_LINKS = [
  {
    text: "Active Probes",
    routeName: "super-admin-active-probes",
  },
  {
    text: "Configure Emails",
    routeName: "configure-emails",
  },
  {
    text: "Email Server Config",
    routeName: "email-server-config",
  },
  {
    text: "Help Text",
    routeName: "help-text",
  },
  {
    text: "Log View",
    routeName: "log-view",
  },
  {
    text: "Testcase Search/Details",
    routeName: "testcase-search-details",
  },
  {
    text: "Organization",
    routeName: "organization",
  },
  {
    text: "Running Subscriber",
    routeName: "running-subscriber",
  },
  {
    text: "Switch User",
    click: () => void 0,
  },
  {
    text: "Super Admin Panel",
    routeName: "super-admin-panel",
  },
  {
    text: "Service Log",
    routeName: "service-log",
  },
  {
    text: "All Running Tests",
    routeName: "all-running-tests",
  },
];
