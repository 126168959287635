import { MenuItem } from "./MenuItem";
import { SUPER_ADMIN_LINKS, SIM_MANAGEMENT_LINKS, CLIENT_MANAGEMENT_LINKS } from "./config/super-admin-menu";

const generateMenuItems = (configList, linkClickMap) => {
  return configList.map((linkConfig) => {
    const onClick = linkClickMap.get(linkConfig.text);
    if (onClick) {
      return MenuItem({ click: onClick, text: linkConfig.text })
    }

    return MenuItem({ routeName: linkConfig.routeName, text: linkConfig.text })
  });
}

// Map<linkText, onClick>
export function useSuperAdminMenu(linkClickMap = new Map) {
  const simManagementLinks = generateMenuItems(SIM_MANAGEMENT_LINKS, linkClickMap);
  const clientManagementLinks = generateMenuItems(CLIENT_MANAGEMENT_LINKS, linkClickMap);
  const superAdminLinks = generateMenuItems(SUPER_ADMIN_LINKS, linkClickMap);

  return {
    simManagementLinks,
    clientManagementLinks,
    superAdminLinks,
  }
}
